
import template from './form_version_add_edit_dialog.html';
import {EditorView, basicSetup} from "codemirror";
import { linter } from "@codemirror/lint";
import {json, jsonParseLinter} from "@codemirror/lang-json";
import { highlightWhitespace, keymap } from "@codemirror/view";
import { indentWithTab } from "@codemirror/commands"
import { indentUnit } from '@codemirror/language';
import comprehensiveTemplate from '../lib/formsTemplates/comprehensive.json';
// TODO don't use codemirror basicSetup and only use needed components

/**
 * View Model
 */
class FormVersionAddEditDialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	self;
	constructor(dialog)
	{
		self = this;
		this.dialog = dialog;
		this.formVersion = ko_helper.safe_observable(this.dialog?.bindings || '');
		this.def = this.formVersion().form_version_def() ? this.formVersion().form_version_def : comprehensiveTemplate.definition;
		this.data = ko.observable();
		this.formInstance = ko.observable();

		//templates
		let templateValues = Array.from(Grape.registry.getRegister('ui-ps-forms-builder.templates').values());//FormTemplateRegistry.templateValues();
		templateValues.unshift({name:"Load a template..."});
		let templates = [];
		for (let templateId in templateValues){
			templates.push({text: `${templateValues[templateId].name} - ${templateValues[templateId].description || ''}`, value: templateId, template:templateValues[templateId]});
		};
		this.templates = ko.observableArray(templates);
		this.selectedTemplate = ko.observable();
		this.selectedTemplate.subscribe((newValue)=>{
			if (newValue == "0")
				return;
			this.template(this.templates()[parseInt(newValue)].template.definition);
		});

		// expressions
		this.expressionInfoVisible = ko.observable(false);
		this.expressions = ko.observableArray(Object.keys(window.FormsClient.ExpressionRegistry.expressions).map((exp)=>{
			return {text:exp, value:exp};
		}));
		this.selectedExpression = ko.observable();
		this.expression = ko.observable();
		this.selectedExpression.subscribe((newValue)=>{
			if (!newValue)
				return;
			console.log(newValue);
			console.log(window.FormsClient.ExpressionRegistry.get(newValue));
			this.expression(window.FormsClient.ExpressionRegistry.get(newValue));
		});

		// effects
		this.effectInfoVisible = ko.observable(false);
		this.effects = ko.observableArray(Object.keys(window.FormsClient.EffectRegistry.effects).map((exp)=>{
			return {text:exp, value:exp};
		}));
		this.selectedEffect = ko.observable();
		this.effect = ko.observable();
		this.selectedEffect.subscribe((newValue)=>{
			if (!newValue)
				return;
			console.log(newValue);
			console.log(window.FormsClient.EffectRegistry.get(newValue));
			this.effect(window.FormsClient.EffectRegistry.get(newValue));
		});

		// Field Info
		this.fieldInfoVisible = ko.observable(false);

		// Rule Info
		this.ruleInfoVisible = ko.observable(false);

		// Layout Info
		this.layoutInfoVisible = ko.observable(false);

		// Form Info
		this.formInfoVisible = ko.observable(false);
	}

	copy(data){
		if (data?.example)
			navigator.clipboard.writeText(JSON.stringify(data.example, null, '\t'));
	}

	showExpressionInfo(){
		this.hideAllInfo()
		this.expressionInfoVisible(!this.expressionInfoVisible());
	}

	showEffectInfo(){
		this.hideAllInfo()
		this.effectInfoVisible(!this.effectInfoVisible());
	}

	showFieldInfo(){
		this.hideAllInfo()
		this.fieldInfoVisible(!this.fieldInfoVisible());
	}

	showRuleInfo(){
		this.hideAllInfo()
		this.ruleInfoVisible(!this.ruleInfoVisible());
	}

	showLayoutInfo(){
		this.hideAllInfo()
		this.layoutInfoVisible(!this.layoutInfoVisible());
	}

	showFormInfo(){
		this.hideAllInfo()
		this.formInfoVisible(!this.formInfoVisible());
	}

	hideAllInfo(){
		this.effectInfoVisible(false);
		this.expressionInfoVisible(false);
		this.fieldInfoVisible(false);
		this.ruleInfoVisible(false);
		this.layoutInfoVisible(false);
		this.formInfoVisible(false);
	}

	template(definition){
		this.dialog.setJSON(JSON.stringify(definition, null, '\t'));
	}

	preview(){
		if (!this.dialog.getJSON()){
			let error = document.querySelector('.cm-lintPoint-error').parentElement;
			error.scrollIntoView({ block: "center" });
			error.animate({ backgroundColor: "red" }, { duration: 500, iterations: 1, easing: "ease-out" });
			return;
		}

		let def = JSON.parse(this.dialog.getJSON());

		Grape.dialog.open('FormDialog', {form_version_def: def, options:{readonly:true, debug:true}});
	}

	async save()
	{
		let def = JSON.parse(this.dialog.getJSON());

		let res = await this.formVersion().saveFormVersion({form_version_def: def});
		if (res.status !== 'OK'){
			Grape.alerts.apiError(res);
			return;
		}

		Grape.alerts.alert({type:'success', title:'Saved', message:'Version Successfully Saved'});
		this.dialog.close(true);
	}

	cancel()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class FormVersionAddEditDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new FormVersionAddEditDialogViewModel(this); // Name of the ViewModel defined above
		this.editor;
	}

	init () {
		console.log(this.viewModel.formVersion().form_version_def());
		this.createEditor('#code-editor', JSON.stringify(this.viewModel.formVersion().form_version_def()||comprehensiveTemplate.definition, null, '\t'));
		new TomSelect("#template",{allowEmptyOption: true});
		new TomSelect("#expressions",{allowEmptyOption: true});
		new TomSelect("#effects",{allowEmptyOption: true});

	};

	updateData(){
	}

	createEditor(target, content){
		this.editor = new EditorView({
			extensions: [
				basicSetup,
				keymap.of([indentWithTab]),
				json(),
				linter(jsonParseLinter()),
				highlightWhitespace(),
				indentUnit.of("\t"),
				EditorView.updateListener.of((v)=>this.editorChanged(v))
			],
			parent: document.querySelector(target)
		});
		if (content)
			this.setJSON(content);
	}

	editorChanged(view){
		if (view.docChanged){
			let json = this.getJSON();
			if (json)
				this.viewModel.formVersion().form_version_def(JSON.parse(json));
		}
	}

	getJSON = ()=>{
		let json = this.editor.state.doc.toString();
		try {
			return JSON.stringify(JSON.parse(json), null, '\t');
		} catch(err){
			return null;
		}
	}

	setJSON = (json)=>{
		this.editor.update([this.editor.state.update({changes: {from: 0, to: this.editor.state.doc.length, insert: json}})]);
	}
}

export default {
	name: 'FormVersionAddEditDialog',
	dialog_class: FormVersionAddEditDialogClass,
	template: template,
	provider: 'ps'
};
