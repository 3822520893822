import template from './form_versions.html';
import BuilderFormModel from '../lib/models/BuilderFormModel';

class FormVersionsViewModel
{
	self;
	constructor (page)
	{
		self = this;
		this.page = page;
		this.form = ko.observable({});
		this.formVersions = ko.observableArray([]);
		this.searchText = ko.observable(this.page.bindings.searchText || '');
		// this.onFormVersionUpdate = ()=>{this.page.updateData()};
	}

	async search(){
		window.location.assign(`/#/ps-forms/form/${this.page.bindings.form_uuid}/versions${(this.searchText() ? '?searchText=':'')+encodeURI(this.searchText())}`);
		this.page.bindings.searchText = this.searchText();
		this.page.updateData();
	}

	async addFormVersion(){
		Grape.dialog.open('FormVersionAddEditDialog', new BuilderFormModel(this.form())).then((output)=>{
			if (output === true){
				this.page.updateData();
			}
		});
	}
}

class FormVersionsPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new FormVersionsViewModel(this);
		this.name = 'FormVersionsPageClass';
	}

	async init ()
	{
	}

	async updateData (page, bindings)
	{
		this.fetchForm(this.bindings.form_uuid).then(
			(data)=>{
				this.fetchFormVersions(data.form_uuid);
			}
		)
	}

	async teardown ()
	{
	}

	async fetchForm(form_uuid){
		return Grape.tables.select({
			schema: 'forms',
			table: 'v_form_safe',
			sortName: 'form_name',
			sortOrder: 'ASC',
			limit: 1,
			filter: [{field:'form_uuid', operator:'=', value: form_uuid}]}
		).then((data)=>{
			this.viewModel.form(data.records[0] || {});
			return data.records[0] || {};
		});
	}

	async fetchFormVersions(form_uuid){
		let filter = [];
		if (this.viewModel.searchText()) {
			filter = filter.concat([
				{field:'form_name', operator:'ILIKE', value:`%${this.viewModel.searchText()}%`}
			]);
		}
		return Grape.fetches.getJSON('/api/record', {
			schema: 'forms',
			table: 'v_form_version_safe',
			sortfield: 'form_version_published_on',
			sortorder: 'DESC',
			limit: 10000,
			filter: filter.concat([{field:'form_uuid', operator:'=', value: form_uuid}]),
			filter_join: 'OR'

		}).then((data)=>{
			let formVersions = [];
			for (let formVersion of data.records){
				formVersions.push(new BuilderFormModel(formVersion));
			};
			this.viewModel.formVersions(formVersions);
			// ko.mapping.fromJS((data.records || []),{}, this.viewModel.formVersions);
			return data.records || [];
		});

	}
}

export default {
	route: '[/]ps-forms/form/:form_uuid/versions',
	page_class: FormVersionsPageClass,
	template: template
}
