
import template from './form_data_config_edit_dialog.html';
import {EditorView, basicSetup} from "codemirror";
import { linter } from "@codemirror/lint";
import {json, jsonParseLinter} from "@codemirror/lang-json";
import { highlightWhitespace, keymap } from "@codemirror/view";
import { indentWithTab } from "@codemirror/commands"
import { indentUnit } from '@codemirror/language';

/**
 * View Model
 */
class FormDataConfigEditDialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	self;
	constructor(dialog)
	{
		self = this;
		this.dialog = dialog;
		this.form = ko.observable(this.dialog?.bindings?.form || '');
		this.form_uuid = ko.observable(this.dialog?.bindings?.form_uuid || this.form().form_uuid || '');
		this.form_name = ko.observable(this.dialog?.bindings?.form_name || this.form().form_name || '');
		this.form_data_uuid = ko.observable(this.dialog?.bindings?.form_data_uuid || '');
		this.form_data_config = ko.observable(this.dialog?.bindings?.form_data_config || '');
	}

	template(){
		this.dialog.setJSON(JSON.stringify({}, null, '\t'));
	}

	save_click()
	{
		Grape.fetches.putJSON(`/forms/api/form/data-config/edit/${self.form_data_uuid()}`, {
			form_data_config: self.form_data_config()
		}).then((res)=>{
			self.dialog.close(res.form_data);
		});
	}

	cancel_click()
	{
		self.dialog.close(false);
	}
}

/**
 * Dialog
 */
class FormDataConfigEditDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new FormDataConfigEditDialogViewModel(this); // Name of the ViewModel defined above
		this.editor;
	}

	init () {
		this.createEditor('#code-editor', JSON.stringify(this.viewModel.form_data_config()||{}, null, '\t'));
	};

	updateData(){
	}

	createEditor(target, content){
		this.editor = new EditorView({
			extensions: [
				basicSetup,
				keymap.of([indentWithTab]),
				json(),
				linter(jsonParseLinter()),
				highlightWhitespace(),
				indentUnit.of("\t"),
				EditorView.updateListener.of((v)=>this.editorChanged(v))
			],
			parent: document.querySelector(target)
		});
		if (content)
			this.setJSON(content);
	}

	editorChanged(view){
		if (view.docChanged){
			let json = this.getJSON();
			if (json)
				this.viewModel.form_data_config(JSON.parse(json));
		}
	}

	getJSON = ()=>{
		let json = this.editor.state.doc.toString();
		try {
			return JSON.stringify(JSON.parse(json), null, '\t');
		} catch(err){
			return null;
		}
	}

	setJSON = (json)=>{
		this.editor.update([this.editor.state.update({changes: {from: 0, to: this.editor.state.doc.length, insert: json}})]);
	}
}

export default {
	name: 'FormDataConfigEditDialog',
	dialog_class: FormDataConfigEditDialogClass,
	template: template,
	provider: 'ps'
};
