
/* Application entry-point */
import './lib/ps-polyfill.js';
import './css/forms_builder.css';

import './lib/formsTemplates/FormTemplatesRegistry.js';

import pages from './pages/index.js';
import Plugin from './lib/FormsBuilderPlugin.js';

import components from './components/index.js';
import dialogs from './dialogs/index.js';

window.Grape.component.registerComponents(components);
window.Grape.dialog.registerDialogs(dialogs);
window.Grape.pages.registerPages(pages);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
	Grape.registry.addItem(
		'PSServerDashboardPages',
		`${pages[0].title}`,
		{
			title: pages[0].title,
			icon: pages[0].icon,
			page_id: pages[0].page_id || pages[0].route || null,
			idx: pages[0].idx
		}
	);

	Grape.plugins.register(Plugin);
});
