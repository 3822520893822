
// PAGES
import forms from './forms.js';
import form_versions from './form_versions.js';
import forms_data from './forms_data.js';

export default [
	forms,
	form_versions,
	forms_data
];
