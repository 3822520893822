
import template from './ps-form-version-card.html';
import BuilderFormModel from '../lib/models/BuilderFormModel';

/**
 * Component View Model
 */
class FormVersionCardComponentViewModel
{
	/**
	 * Component constructor
	 */
	constructor(params)
	{
		this.formVersion = ko.observable(params.formVersion);
		console.log(this.formVersion());
		this.onUpdate = params.onUpdate || (()=>{});
		this.active = ko.computed(()=>{
			return !!this.formVersion()?.form_version_published_on() &&
				!this.formVersion()?.form_version_deprecated_on();
		}, this);
	}

	captureForm(data, onUpdate){
		console.log(data);
		Grape.dialog.open('FormDialog', ko.mapping.toJS(data));// TODO use this.form.serialize
	}

	deleteFormVersion(data, onUpdate){
		Grape.alerts.confirm({type:'warning', title:'Delete Form Version', message:`Are you sure you want to delete Form Version "${data.form_version_uuid()}"?`}).then((confirm)=>{
			if (confirm){
				data.deleteFormVersion().then((res)=>{
				// Grape.fetches.fetchJSON(`/forms/api/form/version/remove/${data.form_version_uuid()}`, {method:'DELETE'}).then((res)=>{
					if (res.status === 'OK')
						onUpdate();
					else
						Grape.alert_api_error(res);
				});
			}

		})
	}

	editFormVersion(data){
		// Grape.dialog.open('FormVersionAddEditDialog', ko.mapping.toJS(data)).then((res)=>{
		// 	if (res)
		// 		this.formVersion(ko.mapping.fromJS(res));
		// });
		Grape.dialog.open('FormVersionAddEditDialog',this.formVersion).then((res)=>{
			console.log(res);
			// if (res)
			// 	this.formVersion(ko.mapping.fromJS(res));
		});
	}

	cloneFormVersion(data, onUpdate){
		let form_version = ko.mapping.toJS(this.formVersion);
		form_version.form_version_uuid = null;
		form_version.form_version = null;
		form_version.form_version_created_on = null;
		form_version.form_version_published_on = null;
		form_version.form_version_published_on = null;
		form_version.form_version_locked = null;
		Grape.dialog.open('FormVersionAddEditDialog', new BuilderFormModel(form_version)).then((res)=>{
			onUpdate();
		});
	}

	publishFormVersion(data, onUpdate){
		Grape.alerts.confirm({type:'warning', title:'Publish Form Version', message:`Are you sure you want to publish Form Version "${data.form_version_uuid()}"?`}).then((confirm)=>{
			if (confirm){
				Grape.fetches.putJSON(`/forms/api/form/version/publish/${data.form_version_uuid()}`).then((res)=>{
					onUpdate();// update everything cause old versions get deprecated
				});
			}

		})
	}

	deprecateFormVersion(data, onUpdate){
		let self = this;
		Grape.alerts.confirm({type:'warning', title:'Deprecate Form Version', message:`Are you sure you want to Deprecate Form Version "${data.form_version_uuid()}"?`}).then((confirm)=>{
			if (confirm){
				Grape.fetches.putJSON(`/forms/api/form/version/deprecate/${data.form_version_uuid()}`).then((res)=>{
					// self.formVersion(ko.mapping.fromJS(res.form_version));
					ko.mapping.fromJS(res.form_version, {}, data);
				});
			}

		})
	}

	viewFormData(data){
		Grape.navigate(`/ps-forms/forms/data?searchText=${encodeURI(data.form_version_uuid())}`);
	}
}

export default {
	name: 'ps-form-version-card',
	viewModel: FormVersionCardComponentViewModel,
	module_type: 'ko',
	template: template
};
