
import template from './form_data_dialog.html';

/**
 * View Model
 */
class FormDataDialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	self;
	constructor(dialog)
	{
		self = this;
		this.form = ko.observable({});
		this.dialog = dialog;
		this.fields = ko.observableArray(['form_name', 'form_version', 'form_data','form_data_uuid', 'form_data_locked']);
	}

	prettyData(){
		return JSON.stringify(this.form().form_data, null, '\t');
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		self.dialog.close(false);
	}
}

/**
 * Dialog
 */
class FormDataDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new FormDataDialogViewModel(this); // Name of the ViewModel defined above
		this.name = 'FormDataDialog'; // Unique dialog name
		this.element = element;
		this.fetchFormsData(this.bindings.form_data_uuid);
	}

	init () {
		this.fetchFormsData(this.bindings.form_data_uuid);
	};

	updateData(){
		this.fetchFormsData(this.bindings.form_data_uuid);
	}

	async fetchFormsData(form_data_uuid){
		return Grape.fetches.getJSON('/api/record', {
			schema: 'forms',
			table: 'v_forms',
			fields: this.viewModel.fields(),
			limit: 1,
			filter: [{field: 'form_data_uuid', operator:'=', value:form_data_uuid}]}
		).then((data)=>{
			this.viewModel.form(data.records[0] || {});
			return data.records[0] || {};
		});
	}
}

export default {
	name: 'FormDataDialog',
	dialog_class: FormDataDialogClass,
	template: template,
	provider: 'ps'
};
