
import template from './form_add_edit_dialog.html';

/**
 * View Model
 */
class FormAddEditDialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} dialog - Dialog class
	 */
	self;
	constructor(dialog)
	{
		self = this;
		this.dialog = dialog;
		this.form_uuid = ko.observable(this.dialog?.bindings?.form_uuid || '');
		this.form_name = ko.observable(this.dialog?.bindings?.form_name || '');
		this.form_description = ko.observable(this.dialog?.bindings?.form_description || '');
	}

	save_click()
	{
		if (this.form_uuid()){
			Grape.fetches.putJSON(`/forms/api/form/edit/${self.form_uuid()}`, {
				form_name: self.form_name(),
				form_description: self.form_description()
			}, {method:'PUT'}).then((res)=>{
				self.dialog.close(res.form);
			});
		}
		else
		{
			Grape.fetches.postJSON('/forms/api/form/add', {
				form_name: self.form_name(),
				form_description: self.form_description()
			}).then((res)=>{
				if (res.status === 'OK'){
					self.dialog.close(res.form);
					return;
				}

				Grape.alert_api_error(res);
			});
		}
	}

	cancel_click()
	{
		self.dialog.close(false);
	}
}

/**
 * Dialog
 */
class FormAddEditDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new FormAddEditDialogViewModel(this); // Name of the ViewModel defined above
	}

	init () {
	};

	updateData(){
	}
}

export default {
	name: 'FormAddEditDialog',
	dialog_class: FormAddEditDialogClass,
	template: template,
	provider: 'ps'
};
