
// COMPONENTS

import ps_form_card from "./ps-form-card.js";
import ps_form_version_card from "./ps-form-version-card.js";
import ps_form_data_card from "./ps-form-data-card.js";

export default [
	ps_form_card,
	ps_form_version_card,
	ps_form_data_card
];
