
class FormsBuilderPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;
	}

	async onInit()
	{

	}

}

export default FormsBuilderPlugin;
