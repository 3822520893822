
// PAGES
import form_add_edit_dialog from './form_add_edit_dialog.js';
import form_version_add_edit_dialog from './form_version_add_edit_dialog.js';
import form_data_dialog from './form_data_dialog.js';
import form_data_config_edit_dialog from './form_data_config_edit_dialog.js';

export default [
	form_add_edit_dialog,
	form_version_add_edit_dialog,
	form_data_dialog,
	form_data_config_edit_dialog
];
