if (!Grape.fetches.putJSON){
	Grape.fetches.__proto__.putJSON = async function(url, data, options={}){
		let headers = new Headers(options.headers || {});
		headers.set('Content-type', 'application/json');
		options.headers = headers;
		options.method = 'PUT';
		if (typeof data == 'string')
			options.body = data;
		else
			options.body = JSON.stringify(data);
		return await this.fetchJSON(url, options);
	}
}
