import template from './forms.html';

class FormsViewModel
{
	self;
	constructor (page)
	{
		self = this;
		this.page = page;
		this.forms = ko.observableArray([]);
		this.searchText = ko.observable(this.page.bindings.searchText || '');
		this.selectedForm = ko.observable();
		this.selectedForm.subscribe((newValue)=>{
			if (newValue){
				this.page.fetchVersions(newValue.form_id);
			}
		});

		this.versions = ko.observableArray([]);
		this.selectedVersion = ko.observable();
		this.onFormUpdate = ()=>{this.page.updateData()};
	}

	async viewForm(data, event){
		Grape.navigate(`ps-forms/form/${data.form_uuid}/versions`);
	}

	async search(){
		Grape.navigate(`ps-forms/forms?searchText=${encodeURI(this.searchText())}`);
	}

	async selectForm(data){
		self.selectedForm(data);
	}

	async selectVersion(data){
		self.selectedVersion(data);
	}

	async addForm(){
		Grape.dialog.open('FormAddEditDialog', {}).then((output)=>{
			if (!!output.form_uuid){
				this.page.updateData();
			}
		});
	}
}

class FormsPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new FormsViewModel(this);
		this.name = 'FormsPageClass';
	}

	async init ()
	{
	}

	async updateData (page, bindings)
	{
		this.fetchForms();
	}

	async teardown ()
	{
	}

	async fetchForms(){
		let filter = [];
		if (this.viewModel.searchText()) {
			filter.push({field:'form_name', operator:'ILIKE', value:`%${this.viewModel.searchText()}%`});
		}
		Grape.tables.select({
			schema: 'forms',
			table: 'v_form_safe',
			sortName: 'form_name',
			sortOrder: 'ASC',
			limit: 10000,
			filter: filter
		}).then((data)=>{
			this.viewModel.forms(data.records || []);
		})
	}

	async fetchVersions(form_id){
		let filter = [];
		if (this.viewModel.searchText()) {
			filter.push({field:'form_name', operator:'ILIKE', value:`%${this.viewModel.searchText()}%`});
		}
		Grape.tables.select({
			schema: 'forms',
			table: 'v_form_versions_safe',
			sortName: 'form_version',
			sortOrder: 'DESC',
			limit: 10000,
			filter: [{field:'form_uuid', operator:'=', value:form_id}]
		}).then((data)=>{
			this.viewModel.versions(data.records || []);
		})
	}
}

export default {
	route: '[/]ps-forms/forms',
	page_class: FormsPageClass,
	template: template,
	page_id: '[/]ps-forms/forms',
	title: 'Forms',
	icon: 'fa fa-box-archive',
	idx: 11
}
