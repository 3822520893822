
import template from './ps-form-data-card.html';

/**
 * Component View Model
 */
class FormDataCardComponentViewModel
{
	/**
	 * Component constructor
	 */
	self;
	constructor(params)
	{
		self = this;
		this.formData = ko.observable(ko.mapping.fromJS(params.formData));
		this.onUpdate = params.onUpdate || (()=>{});
	}

	viewFormData(data){
		Grape.dialog.open('FormDataDialog', {form_data_uuid: data.form_data_uuid()});
	}

	editFormData(data){
		Grape.dialog.open('FormDialog', ko.mapping.toJS(data));
	}
	//TODO make read only mode for form so visually its clear you can't modify the data
	viewForm(data){
		Grape.dialog.open('FormDialog', Object.assign(ko.mapping.toJS(data), {options:{readonly:true}}));
	}

	viewFormVersions(data){
		Grape.navigate(`ps-forms/form/${data.form_uuid()}/versions`);
	}

	editFormDataConfig(data){
		let onUpdate = self.onUpdate;
		let def = ko.mapping.toJS(data);
		Grape.dialog.open('FormDataConfigEditDialog', def).then((res)=>{
			ko.mapping.fromJS(res, data);
			onUpdate();
		});
	}

	deleteFormData(data){
		let onUpdate = self.onUpdate;
		Grape.alerts.confirm({type:'warning', title:'Delete Form Data', message:`Are you sure you want to delete Form Data "${data.form_data_uuid()}"?`}).then((confirm)=>{
			if (confirm){
				Grape.fetches.fetchJSON(`/forms/api/form/data/remove/${data.form_data_uuid()}`, {method:'DELETE'}).then((res)=>{
					if (res.status === 'OK')
						onUpdate();
					else
						Grape.alert_api_error(res);
				});
			}

		})
	}

	toggleFormDataLocked(data){
		if (data.form_data_locked()){
			Grape.fetches.putJSON(`/forms/api/form/data/unlock/${data.form_data_uuid()}`).then((res)=>{
				if (res.status === 'OK')
					ko.mapping.fromJS(res.form_data, data)
				else
					Grape.alert_api_error(res);
			});
		} else {
			Grape.fetches.putJSON(`/forms/api/form/data/lock/${data.form_data_uuid()}`).then((res)=>{
				if (res.status === 'OK')
					ko.mapping.fromJS(res.form_data, data);
				else
					Grape.alert_api_error(res);
			});
		}
	}

	lockFormData(data){
		Grape.fetches.putJSON(`/forms/api/form/data/lock/${data.form_data_uuid()}`).then((res)=>{
			if (res.status === 'OK')
				ko.mapping.fromJS(res.form_data, data);
			else
				Grape.alert_api_error(res);
		});
	}

	unlockFormData(data){
		Grape.fetches.putJSON(`/forms/api/form/data/unlock/${data.form_data_uuid()}`).then((res)=>{
			if (res.status === 'OK')
				ko.mapping.fromJS(res.form_data, data)
			else
				Grape.alert_api_error(res);
		});
	}
}

export default {
	name: 'ps-form-data-card',
	viewModel: FormDataCardComponentViewModel,
	module_type: 'ko',
	template: template
};
