
import template from './ps-form-card.html';

/**
 * Component View Model
 */
class FormCardComponentViewModel
{
	self;
	/**
	 * Component constructor
	 */
	constructor(params)
	{
		self = this;
		this.form = ko.observable(ko.mapping.fromJS(params.form));
		this.onUpdate = params.onUpdate || (()=>{});
	}

	showVersions(data){
		Grape.navigate(`ps-forms/form/${data.form_uuid()}/versions`);
	}

	viewForm(data){
		Grape.dialog.open('FormDialog', {form_uuid: data.form_uuid(), options:{readonly:true}});
	}

	captureForm(data){
		Grape.dialog.open('FormDialog', {form_uuid: data.form_uuid()});
	}

	deleteForm(data){
		let onUpdate = self.onUpdate;
		Grape.alerts.confirm({type:'warning', title:'Delete Form', message:`Are you sure you want to delete Form "${self.form().form_name}"?`}).then((confirm)=>{
			if (confirm){
				Grape.fetches.fetchJSON(`/forms/api/form/remove/${data.form_uuid()}`, {method:'DELETE'}).then((res)=>{
					if (res.status === 'OK')
						onUpdate();
					else
						Grape.alert_api_error(res);
				});
			}
		})
	}

	editForm(data){
		Grape.dialog.open('FormAddEditDialog', ko.mapping.toJS(data)).then((res)=>{
			ko.mapping.fromJS(res, data);
		});
	}

	toggleFormEnabled(data){
		if (data.form_enabled())
			Grape.fetches.putJSON(`/forms/api/form/disable/${data.form_uuid()}`).then((res)=>{ko.mapping.fromJS(res.form, data);});
		else
			Grape.fetches.putJSON(`/forms/api/form/enable/${data.form_uuid()}`).then((res)=>{ko.mapping.fromJS(res.form, data);});
	}

	viewFormData(data){
		Grape.navigate(`/ps-forms/forms/data?searchText=${encodeURI(data.form_uuid())}`);
	}
}

export default {
	name: 'ps-form-card',
	viewModel: FormCardComponentViewModel,
	module_type: 'ko',
	template: template
};
