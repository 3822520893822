import template from './forms_data.html';
import BuilderFormModel from '../lib/models/BuilderFormModel';

class FormsDataViewModel
{
	self;
	constructor (page)
	{
		self = this;
		this.page = page;
		this.formsData = ko.observableArray([]);
		this.fields = ko.observableArray(['form_name','form_uuid', 'form_version_uuid', 'form_data', 'form_data_config','form_data_uuid', 'form_data_locked', 'form_version', 'form_version_def']);
		this.sortName = ko.observable('form_data_updated_at');
		this.sortOrder = ko.observable('ASC');
		this.filterFields = ko.observableArray(this.fields());
		this.filterJoin = ko.observable('OR');
		this.searchText = ko.observable(this.page.bindings.searchText || '');
		this.onFormUpdate = ()=>{this.page.updateData()};
	}

	async search(){
		window.location.assign(`/#/ps-forms/forms/data${(this.searchText() ? '?searchText=':'')+encodeURI(this.searchText())}`);
		this.page.bindings.searchText = this.searchText();
		this.page.updateData();
	}
}

class FormsDataPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new FormsDataViewModel(this);
		this.name = 'FormsDataPageClass';
	}

	async init ()
	{
		this.viewModel.searchText(this.bindings.searchText || '');
	}

	async updateData (page, bindings)
	{
		this.viewModel.searchText(this.bindings.searchText || '');
		this.fetchFormsData();
	}

	async teardown ()
	{
	}

	async fetchFormsData(){
		let filter = [];
		if (this.viewModel.searchText()) {
			for (let field of this.viewModel.filterFields()){
				filter.push({field: field, operator:'ILIKE', value:`%${this.viewModel.searchText()}%`});
			}
		}
		return Grape.fetches.getJSON('/api/record', {
			schema: 'forms',
			table: 'v_form_data_safe',
			sortName: 'form_data_uuid',
			sortOrder: 'ASC',
			fields: this.viewModel.fields(),
			limit: 10000,
			filter: filter,
			filter_join: this.viewModel.filterJoin()}
		).then((data)=>{
			let formsData = [];
			for (let formData of data.records){
				formsData.push(new BuilderFormModel(formData));
			};
			this.viewModel.formsData(formsData);
			return data.records || [];
		});
	}
}

export default {
	route: '[/]ps-forms/forms/data',
	page_class: FormsDataPageClass,
	template: template
}
