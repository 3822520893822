class BuilderFormModel {
	constructor({ form_created_on, form_description, form_enabled, form_name, form_uuid, form_version, form_version_created_on, form_version_def, form_version_deprecated_on, form_version_description, form_version_published_on, form_version_uuid, form_data_uuid, form_data, form_data_locked, form_data_config}){
		this.form_created_on = ko_helper.safe_observable(form_created_on);
		this.form_description = ko_helper.safe_observable(form_description);
		this.form_enabled = ko_helper.safe_observable(form_enabled);
		this.form_name = ko_helper.safe_observable(form_name);
		this.form_uuid = ko_helper.safe_observable(form_uuid);
		this.form_version = ko_helper.safe_observable(form_version);
		this.form_version_created_on = ko_helper.safe_observable(form_version_created_on);
		this.form_version_def = ko_helper.safe_observable(form_version_def);
		this.form_version_deprecated_on = ko_helper.safe_observable(form_version_deprecated_on);
		this.form_version_description = ko_helper.safe_observable(form_version_description);
		this.form_version_published_on = ko_helper.safe_observable(form_version_published_on);
		this.form_version_uuid = ko_helper.safe_observable(form_version_uuid);
		this.form_data_uuid = ko_helper.safe_observable(form_data_uuid);
		this.form_data = ko_helper.safe_observable(form_data);
		this.form_data_locked = ko_helper.safe_observable(form_data_locked);
		this.form_data_config = ko_helper.safe_observable(form_data_config);
	}

	async fetchForm(){
		// TODO should get all data
		let form = await Grape.fetches.fetchJSON(`/forms/api/forms/find?form_data_uuid=${this.form_data_uuid()}&form_version_uuid=${this.form_version_uuid()}&form_uuid=${this.form_uuid()}&form_name=${this.form_name()}&form_version=${this.form_version()}`)

		if (d.status === 'OK'){
			this.form_created_on(d.form_created_on);
			this.form_description(d.form_description);
			this.form_enabled(d.form_enabled);
			this.form_name(d.form_name);
			this.form_uuid(d.form_uuid);
			this.form_version(d.form_version);
			this.form_version_created_on(d.form_version_created_on);
			this.form_version_def(d.form_version_def);
			this.form_version_deprecated_on(d.form_version_deprecated_on);
			this.form_version_description(d.form_version_description);
			this.form_version_published_on(d.form_version_published_on);
			this.form_version_uuid(d.form_version_uuid);
			this.form_data_locked(d.form_data_locked);
			this.form_data_config(d.form_data_config);
		}

		return form;
	};

	getForm(){};
	getFormVersion(){};
	getFormData(){};

	saveForm(){};
	deleteForm(){};

	saveFormVersion(overrides={}){
		let formVersion = Object.assign(ko.mapping.toJS(this), overrides);
		if (this.form_version_uuid()){
			return Grape.fetches.putJSON(`/forms/api/form/version/edit/${formVersion.form_version_uuid}`, {
				form_version_description: formVersion.form_version_description,
				form_version_def: formVersion.form_version_def
			});
		}
		else
		{
			return Grape.fetches.postJSON('/forms/api/form/version/add', {
				form_uuid: formVersion.form_uuid,
				form_version_description: formVersion.form_version_description,
				form_version_def: formVersion.form_version_def
			});
		}
	};
	deleteFormVersion(){
		return Grape.fetches.fetchJSON(`/forms/api/form/version/remove/${this.form_version_uuid()}`, {method:'DELETE'})
	};
	publishFormVersion(){};
	deprecateFormVersion(){};
	lockFormVersion(){};
	unlockFormVersion(){}


	saveFormData(){}
	deleteFormData(){}
	submitFormData(){};

}

module.exports = BuilderFormModel;
