const captureAddressDetailsTemplate = require('./captureAddressDetails.json');
const comprehensiveTemplate = require('./comprehensive.json');
const simpleFieldTemplate = require('./simpleField.json');
const simpleFieldValidatorTemplate = require('./simpleFieldValidator.json');

Grape.registry.addRegister('ui-ps-forms-builder.templates');
Grape.registry.addItem('ui-ps-forms-builder.templates', captureAddressDetailsTemplate.name, captureAddressDetailsTemplate);
Grape.registry.addItem('ui-ps-forms-builder.templates', comprehensiveTemplate.name, comprehensiveTemplate);
Grape.registry.addItem('ui-ps-forms-builder.templates', simpleFieldTemplate.name, simpleFieldTemplate);
Grape.registry.addItem('ui-ps-forms-builder.templates', simpleFieldValidatorTemplate.name, simpleFieldValidatorTemplate);
